<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-settings">Banner</div>
    <div class="flex justify-center relative">
      <div
        class="absolute bottom-0 p-3 border border-error-border text-error-text bg-error-bg rounded"
        v-if="error"
      >
        <div role="alert">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-3 pt-5">
      <div class="col-start-1 col-span-1">
        <div>
          <div class="box-style">
            <div class="fourthhead-text pb-3">
              Upload banner new student list (as .csv)
            </div>
            <input
              class="form-control block w-full px-3 py-1.5 text-base bg-white bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:bg-white focus:border-purple focus:outline-none"
              type="file"
              id="formFile"
              @change="onFileChange"
            />
            <div class="text-center mt-3">
              <button
                class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white disabled:bg-slate-gray"
                @click="uploadFile"
                :disabled="!file || is_uploading"
                data-cy="btn-upload-file"
              >
                Upload File
              </button>
            </div>
          </div>
        </div>
        <div id="upload-stats" class="pt-3">
          <div class="box-style">
            <div>Status: {{ status.toUpperCase() }}</div>
            <div>Count: {{ count }} students processed</div>
            <div>Duplicates: {{ duplicates.length }} found</div>
          </div>
        </div>
        <div id="student-promo-button-container" class="text-center pt-3">
          <button
            aria-label="GOTO Promote students"
            class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white disabled:bg-slate-gray"
            :disabled="count <= 0"
            @click="$router.push({ name: 'AdminStudentPromotion' })"
          >
            Approve Student Promotions
          </button>
        </div>
      </div>
      <div class="col-start-2 col-span-2">
        <div class="box-style">
          <div v-if="status">
            <dynamic-table
              :records="duplicates"
              :fields="table_config"
              :no-data-msg="'No duplicates found!'"
              striped
              hover
            >
              <template #cell(duplicate-name)="{ item }">
                <div>
                  {{ item.detail }}
                </div>
              </template>
              <template #cell(duplicate-message)="{ item }">
                <div>
                  {{ item.msg }}
                </div>
              </template>
            </dynamic-table>
          </div>
          <div v-else>
            <div class="fourthhead-text pb-3">
              Results will be displayed here.
            </div>
            <div v-if="is_polling || is_uploading">
              <loading-spinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S3_utils from "@/composables/s3_utils";
import Sqs_utils from "@/composables/sqs_utils";
import { refreshUserTokenInformation } from "@/composables/tokenUtils";
import { uuid } from "uuidv4";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";

export default {
  name: "AdminBanner",
  components: { LoadingSpinner, DynamicTable },
  created() {
    refreshUserTokenInformation(); 
  },
  data() {
    return {
      retries: 0,
      is_uploading: false,
      is_polling: false,

      table_config: [
        {
          display: "Message",
          name: "duplicate-message",
          sortable: false,
          class: "",
        },
        {
          display: "Student ID/Name",
          name: "duplicate-name",
          sortable: false,
          class: "",
        },
      ],

      filename: "",
      file: null,
      status: "",
      count: 0,
      duplicates: [],
      upload_progress: 0,

      cancel_sqs: false,
      error: null,
    };
  },
  methods: {
    refreshUserTokenInformation,
    onFileChange: function (e) {
      this.file = e.target.files[0];
    },
    uploadFile: function () {
      if (!this.is_uploading) {
        this.is_uploading = true;
        this.error = null;
        this.cancel_sqs = false;
        this.filename = uuid();
        S3_utils.uploadFile(
          this.filename,
          this.file,
          (err) => {
            this.error = err;
            // this.is_uploading = false;
          },
          (progress) => {
            this.upload_progress = progress;
          },
          () => {
            this.listenToQueue();
          }
        );
      }
    },
    listenToQueue: function () {
      this.is_polling = true;
      Sqs_utils.getMessage(
        (data) => {
          this.handleSQSMessage(data);
        },
        (err) => {
          // this.error = err;
          console.log("error: (listenToQueue):", err);
          this.is_polling = false;
        }
      );
    },
    handleSQSMessage: function (data) {
      if (this.cancel_sqs) {
        return;
      }
      let polling = true;
      if (data.Messages.length) {
        const allMessages = data.Messages.map((msg) => {
          // console.log(msg);
          return {
            receiptHandle: msg.ReceiptHandle,
            body: msg.Body,
            msgId: msg.MessageId,
          };
        });
        // console.log(allMessages);
        const relevantMessage = allMessages[0];
        if (relevantMessage) {
          const { body: msgBody } = relevantMessage;
          // json parse msgBody
          const parsedMsgBody = JSON.parse(msgBody);
          // console.log(parsedMsgBody);
          if (parsedMsgBody) {
            this.status = parsedMsgBody.status ? parsedMsgBody.status : "";
            switch (this.status) {
              case "complete":
                this.count = parsedMsgBody.count;
                this.duplicates = parsedMsgBody.duplicates;
                break;
              case "error":
                this.error = parsedMsgBody.error;
                break;
              default:
                break;
            }
          }
          polling = false;
          Sqs_utils.deleteMessage(relevantMessage.receiptHandle);
        }
      } else {
        this.retries++;
        this.error =
          "Failed to poll SQS for new messages. Trying Attempt: " +
          (this.retries + 1);
      }
      // Listen for the next message
      if (polling) {
        if (this.retries > 10) {
          this.error =
            "Failed to poll SQS for new messages. Retries exhausted.";
          this.is_polling = false;
          this.cancel_sqs = true;
        } else {
          setTimeout(() => {
            // Make sure we're not already listening for another message and that we
            // haven't cancelled before we attempt to listen again.
            if (!this.cancel_sqs && this.is_polling) {
              this.listenToQueue();
            }
          }, 3000);
        }
      } else {
        // If we make it here it means we've processed all our messages, so we'll unset our
        // status queue timeout as it's no longer needed.
        this.cancel_sqs = true;
        this.is_polling = false;
        // this.is_uploading = false;
      }
    },
  },
};
</script>

<style scoped></style>
