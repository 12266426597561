import AWS from "aws-sdk";
import { getAWSCredentials } from "@/composables/tokenUtils";

const S3Config = {
  AwsRegion: process.env.VUE_APP_AWS_REGION,
  UploadBucket: process.env.VUE_APP_UPLOAD_BUCKET,
  //"WorkspaceStatusQueue": "https://sqs.us-east-1.amazonaws.com/963191115448/datacat-kitchen-ui_status",
};

// https://www.npmjs.com/package/aws-s3
// Client to interact with S3

class S3Service {
  constructor() {
    this.Client = null;
  }

  // Returns the client object
  getClient() {
    if (!this.Client) {
      const keys = getAWSCredentials();
      const options = {
        region: S3Config.AwsRegion,
        credentials: {
          accessKeyId: keys.access,
          secretAccessKey: keys.secret,
          sessionToken: keys.session,
        },
      };
      this.Client = new AWS.S3(options);
    }

    return this.Client;
  }

  /**
   * Handles the upload to S3 for workspace files.
   */
  uploadWorkspaceFile(filename, body, complete, error, update) {
    this.uploadFile(filename, body, complete, error, update);
  }

  /**
   * Handles the upload to S3 using multipart process - support any size file
   */
  uploadFile(filename, body, error, update, complete) {
    console.log("uploadFile", filename, body);
    let options = { partSize: 5 * 1024 * 1024, queueSize: 1 };
    let managedObj = this.getClient().upload(
      {
        Bucket: S3Config.UploadBucket,
        Key: filename,
        Body: body,
      },
      options,
      function (err, data) {
        if (err) {
          error(err);
        } else {
          complete(data);
        }
      }
    );
    managedObj.on("httpUploadProgress", function (progress) {
      update((progress.loaded / progress.total) * 100);
    });
  }
}

const service = new S3Service();

export default service;
