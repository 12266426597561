import AWS from "aws-sdk";
import { getAWSCredentials } from "@/composables/tokenUtils";

// https://www.npmjs.com/package/aws-s3
// Client to interact with S3
const SQSConfig = {
  AwsRegion: process.env.VUE_APP_AWS_REGION,
  AwsSqsQueueUrl: process.env.VUE_APP_AWS_SQS_QUEUE_URL,
}

class SQSService {
  constructor() {
    this.Client = null;
  }

  getClient() {
    if (!this.Client) {
      const keys = getAWSCredentials();
      const options = {
        region: SQSConfig.AwsRegion,
        credentials: {
          accessKeyId: keys.access,
          secretAccessKey: keys.secret,
          sessionToken: keys.session,
        },
      };
      this.Client = new AWS.SQS(options);
    }

    return this.Client;
  }

  getMessage(complete, error) {
    console.log("getMessage");
    console.log(this.getClient());
    this.getClient().receiveMessage(
      {
        QueueUrl: SQSConfig.AwsSqsQueueUrl,
        WaitTimeSeconds: 20,
      },
      function (err, data) {
        if (err) {
          error(err);
        } else {
          complete(data);
        }
      }
    );
  }

  deleteMessage(msgId) {
    console.log(msgId);
    this.getClient().deleteMessage(
      {
        QueueUrl: SQSConfig.AwsSqsQueueUrl,
        ReceiptHandle: msgId,
      },
      function (err) {
        if (err) {
          console.log(err);
          console.log(err.stack);
        }
      }
    );
  }
}

const service = new SQSService();

export default service;
